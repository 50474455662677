// BOILERPLATE FOR PAGES
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PageWrapper from "../PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import heroSvg from '../img/undraw/undraw_online_articles_79ff.svg';
import LearningCentreGridItem from '../components/LearningCentreGridItem';

function Hero({ bgImage }){
  return (
      <>
          <div className="header-hero bg_cover d-lg-flex align-items-center">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-5">
                          <div className="header-hero-content">
                              <h1 className="hero-title"><b>Learning Centre</b></h1>
                              <p className="hero-subtitle mt-3">In this place you'll find articles from various contributors with regards to quality assurance in localization. Your contribution is more than welcome, so feel free to contact us if you want your article to be added to our learning centre.</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="header-hero-image d-flex align-items-center">
                  <div className="image">
                      <img src={bgImage} alt="Hero" />
                  </div>
              </div>
          </div>
      </>

  );

}

const LearningCenter = ({ data }) => {

    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges;
  
    return (
  
      <PageWrapper>

        <Header component={<Hero bgImage={heroSvg}/>} title={siteTitle} />

        <SEO title="lexiQA Learning Centre - The Future of Linguistic QA" />
        <SEO description="Learn about emerging trends and new methodologies for quality assurance in the localization industry" />
        <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: `{ 
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What’s the difference between LQA and LQE?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Linguistic Quality Assurance (LQA) is a process which is put in place to check for quality issues that need to be addressed in the output of a translation or localization workflow. Linguistic Quality Evaluation (LQE) is a process which produces a quality score for any given translation output, determined by a set of quality benchmarks which are assigned a score based on severity."
                }
            }, {
                "@type": "Question",
                "name": "Is it enough to only have LQE for your translations?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The short answer is: no. LQE was introduced as a compromise when volumes of translated content became too large to check with LQA (using the technology of the time). Because LQE is normally done after your translations are completed and published, and it’s normally only done manually on a small sample of the total published content, it can only serve as an afterthought that may help improve processes (but not necessarily the output) in the future."
                }
            }, {
                "@type": "Question",
                "name": "How can you improve on translation quality?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A good quality program should include (a) an automated LQA system which will be accurate and reliable, (b) a streamlined LQE mechanism to automatically track performance in current projects and quickly compare it to that of old projects, and (c) tools that can be integrated and managed online together with existing TMS, CAT and other business platforms."
        }`}} />

        <section className="learning-center pt-0 pb-120 bg-white">
          <div className="container">
            <div className="row">
              { posts.map( ( { node } ) => {
                const title = node.frontmatter.title || node.fields.slug
                return <LearningCentreGridItem 
                  key={ node.fields.slug } 
                  node={ node } 
                  title={ title } 
                  />;
              } ) }
            </div>
          </div>
        </section>
  
        <Footer/>
  
      </PageWrapper>
    )
}

export default LearningCenter;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/learning-centre/"} }
      sort: { fields: [frontmatter___id], order: ASC }
      ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            author
            title
            thumbnail
          }
        }
      }
    }
  }
`

