import React from 'react';
import { Link } from "gatsby";
import "./LearningCentreGridItem.scss";

function LearningCenterGridItem({ node, title }){

    return (
        <>
            <article className="learning-center--component col-md-8 offset-md-2 col-sm-12 mb-5">                
                <div className="single-blog mt-30">
                    {/*
                    <div className="blog-image">
                        <a href={node.fields.slug}>
                        <img src={ node.frontmatter.thumbnail } alt="news" /> 
                        </a>
                    </div>
                    */}
                    <div className="blog-content">
                        <h4 className="blog-title">
                            <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                                {title}
                            </Link>
                            <small className="blog-date">{node.frontmatter.date}</small>
                        </h4>
                        <div className="blog-author d-flex align-items-center">
                            <div className="author-content media-body pl-0">
                                {/* <h6 className="sub-title">Posted by <span className="font-weight-bold">{ node.frontmatter.author }</span></h6> */}
                                <p className="text" dangerouslySetInnerHTML={{ __html: node.frontmatter.description || node.excerpt }}></p>
                                <Link className="btn btn-primary mt-4" style={{ boxShadow: `none` }} to={node.fields.slug}>Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
        </article>
      </>
    );

}

export default LearningCenterGridItem;